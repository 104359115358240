import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Navbar from '../components/navbar';
import '../styles/global.css';
import '../styles/faq.css';
import SEO from '../SEO';
import BenefitDepositoBPR from '../components/benefitDepositoBPR';
import CardDepositoBPR from '../components/cardDepositoBPR';
import ApaItuBPR from '../components/apaItuBPR';
import { Footer } from '../components/footer';
import { pageMeta } from '../utils/common';
import Wrapper from '../components/Wrapper';
import GTM_TRACKERS from '../../constants/gtm-trackers';

const Kalkulator = () => {
  const [formData, setFormData] = useState({
    depositoAwal: null,
    tenor: null,
    bunga: null,
    pajak: 20,

    depositoAwalDisplay: null,
    tenorDisplay: null,
    bungaDisplay: null,
    pajakDisplay: '20%',
  });

  const [result, setResult] = useState({
    bungaSebelumPajak: 0,
    bungaSetelahPajak: 0,
    danaSetelahTenor: 0,
  });

  const handleChangeDana = (e) => {
    const inputValue = e.target.value;
    const numericValue = Number(inputValue.replace(/[^\d]/g, ''));
    if (numericValue === 0) {
      setFormData((prevValues) => ({
        ...prevValues,
        depositoAwal: null,
        depositoAwalDisplay: null,
      }));
    } else if (!Number.isNaN(numericValue)) {
      setFormData((prevValues) => ({
        ...prevValues,
        depositoAwal: numericValue.toFixed(0),
        depositoAwalDisplay: `Rp${numericValue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`,
      }));
    }
  };

  const handleChangeTenor = (e) => {
    const inputValue = e.target.value;
    setFormData((prevValues) => ({
      ...prevValues,
      tenorDisplay: inputValue,
      tenor: inputValue,
    }));
  };

  const handleChangeBunga = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');

    if (numericValue === 0) {
      setFormData((prevValues) => ({
        ...prevValues,
        bunga: null,
        bungaDisplay: null,
      }));
    } else if (!Number.isNaN(numericValue)) {
      setFormData((prevValues) => ({
        ...prevValues,
        bunga: numericValue,
        bungaDisplay: `${numericValue}%`,
      }));
    }
  };

  const handleHitung = () => {
    const {
      depositoAwal, tenor, bunga, pajak,
    } = formData;
    if (depositoAwal && tenor && bunga && pajak) {
      const bungaSebelumPajak = (depositoAwal * (bunga / 12) * tenor) / 100;
      // Check if depositoAwal is less than 7500000
      let bungaSetelahPajak;
      if (Number(depositoAwal) < 7500000) {
        bungaSetelahPajak = bungaSebelumPajak;
      } else {
        bungaSetelahPajak = bungaSebelumPajak * ((100 - (pajak || 0)) / 100);
      }
      const danaSetelahTenor = Number(depositoAwal) + bungaSetelahPajak;
      setResult({
        bungaSebelumPajak: bungaSebelumPajak.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        bungaSetelahPajak: bungaSetelahPajak.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        danaSetelahTenor: danaSetelahTenor.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });
    }
  };

  const onKeyDownDana = (e) => {
    const { key } = e;
    if (key !== 'Backspace' && !/[0-9.]/.test(key)) {
      e.preventDefault();
    }
  };

  const onKeyDownBunga = (e) => {
    const { key } = e;
    if (e.key === 'Backspace') {
      setFormData({
        ...formData,
        bungaDisplay: '',
      });
    } else if (key !== '.' && !/[0-9]/.test(key)) {
      e.preventDefault();
    }
  };

  const handleSetUlang = () => {
    setFormData({
      ...formData,
      depositoAwal: null,
      tenor: null,
      bunga: null,

      depositoAwalDisplay: '',
      tenorDisplay: '',
      bungaDisplay: '',
    });
    setResult({
      bungaSebelumPajak: 0,
      bungaSetelahPajak: 0,
      danaSetelahTenor: 0,
    });
  };

  return (
    <Wrapper>
      <SEO
        title={pageMeta.kalkulatorTitle}
        metaDescription={pageMeta.kalkulatorDesc}
        cannonicalPath="kalkulator"
      />
      <Navbar />
      <div className="bg-blue-light40 px-5 lg:px-0 py-10 relative">
        <div className="calc-vector w-1/4 hidden lg:block absolute top-0 right-0">
          <StaticImage
            src="../images/vector-calc2.png"
            alt="vector-img"
            objectFit="cover"
          />
        </div>
        <div className="flex justify-center items-center h-full">
          <div className="box-title-calc-top lg:grid lg:grid-cols-10 container mx-auto">
            <div className="vector-calc w-1/4 hidden lg:block">
              <StaticImage
                src="../images/vector-calc.png"
                alt="vector-img"
                objectFit="cover"
                className="absolute top-0 right-0"
                style={{ borderRadius: '20px 0 0 0' }}
              />
            </div>
            <div className="vector-calc w-3/5 block lg:hidden">
              <StaticImage
                src="../images/vector-calc3.png"
                alt="vector-img"
                objectFit="cover"
              />
            </div>
            <div className="text-white lg:col-span-4 pl-5 lg:pl-10 lg:pr-0 pr-5 lg:pt-24 pt-14">
              <p className="font-bold lg:mb-5 mb-8 flex justify-center lg:block">
                <span className="text-gray-400 font-normal">Beranda /</span>
                Kalkulator
              </p>
              <h1 className="text-3xl font-bold mb-2 lg:text-left text-center flex justify-center lg:block">Kalkulator Deposito</h1>
              <h2 className="text-3sm font-semibold lg:text-left text-center mb-5 flex justify-center lg:block">Simulasi Bunga Deposito Berjangka Bank BPR</h2>
              <p className="flex justify-center lg:text-left text-center lg:block mb-12">
                Kalkulator deposito ditujukan untuk menghitung
                simulasi bunga deposito dan dana Anda setelah
                tenor berakhir, Simulasikan Deposito Anda Sekarang!
              </p>
            </div>
            <div className="lg:col-span-2" />
            <div className="mt-5 lg:mr-10 lg:col-span-4 lg:mb-5 mb-8 pb-5 lg:pt-0 pt-5 lg:pb-0 lg:block flex justify-center">
              <div className="lg:w-full w-3/4">
                <StaticImage
                  src="../images/calcBro.png"
                  alt="investation app"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center h-full">
          <div className="box-title-calc-bottom lg:grid lg:grid-cols-10 container mx-auto pt-3">
            <div className="text-dark lg:col-span-5 lg:pl-5 mb-5 lg:mr-2 mr-10 lg:ml-0 ml-10">
              <h3 className="lg:text-3sm text-2lg font-semibold mt-5 mb-5">Masukkan data</h3>
              <p className="mt-5 mb-1">
                Dana Deposito Awal
                <span className="text-red">*</span>
              </p>
              <input
                type="text"
                name="depositoAwal"
                value={formData.depositoAwalDisplay}
                onKeyDown={onKeyDownDana}
                onChange={handleChangeDana}
                placeholder="Contoh: Rp10.000.000"
                style={{
                  border: '1px solid #C2C9D1',
                  background: '#FAFAFA',
                  borderRadius: '10px',
                  width: '100%',
                }}
              />
              <p className="mt-5 mb-1">Tenor Deposito (Bulan)</p>
              <select
                name="tenor"
                value={formData.tenorDisplay}
                onChange={handleChangeTenor}
                className="custom-dropdown"
              >
                <option value="" selected disabled>Pilih Tenor</option>
                <option value="1">1 Bulan</option>
                <option value="2">2 Bulan</option>
                <option value="3">3 Bulan</option>
                <option value="4">4 Bulan</option>
                <option value="5">5 Bulan</option>
                <option value="6">6 Bulan</option>
                <option value="7">7 Bulan</option>
                <option value="8">8 Bulan</option>
                <option value="9">9 Bulan</option>
                <option value="10">10 Bulan</option>
                <option value="11">11 Bulan</option>
                <option value="12">12 Bulan</option>
              </select>
              <p className="mt-5 mb-1">Bunga Deposito Tahunan</p>
              <input
                type="text"
                name="bunga"
                value={formData.bungaDisplay}
                onKeyDown={onKeyDownBunga}
                onChange={handleChangeBunga}
                placeholder="Contoh: 5.25%"
                style={{
                  border: '1px solid #C2C9D1',
                  background: '#FAFAFA',
                  borderRadius: '10px',
                  width: '100%',
                }}
              />
              <p className="mt-5 mb-1">Pajak Bunga Deposito</p>
              <input
                type="text"
                name="pajak"
                value={formData.pajakDisplay}
                placeholder="Pajak"
                disabled
                style={{
                  border: '1px solid #C2C9D1',
                  background: '#ECECEC',
                  borderRadius: '10px',
                  width: '100%',
                  color: 'GrayText',
                }}
              />
              <div className="box-warning">
                <p>
                  *Pajak bunga deposito adalah 20%.&nbsp;
                  <a
                    href="https://depositobpr.id/blog/pajak-deposito-kenali-arti-dan-cara-menghitungnya"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="box-text-hyperlink"
                  >
                    Cari tahu lebih tentang ini
                  </a>
                </p>
              </div>
              <div className="lg:flex lg:justify-end lg:mt-10 mt-5 mb-5 grid grid-cols-2">
                <div className={GTM_TRACKERS.KALKULATOR.RESET}>
                  <button type="button" className="buttonSetUlang px-2 lg:px-9 py-3 text-blue-light40 mr-3" onClick={handleSetUlang}>
                    Set Ulang
                  </button>
                </div>
                <div className={GTM_TRACKERS.KALKULATOR.HITUNG}>
                  <button
                    type="button"
                    className="buttonHitung px-2 lg:px-12 py-3 text-white bg-blue-light40"
                    onClick={handleHitung}
                  >
                    Hitung
                  </button>
                </div>
              </div>
            </div>
            <div className="text-dark lg:col-span-5 lg:pr-5 pr-10 mb-5 lg:ml-2 ml-10">
              <h3 className="text-3sm font-semibold lg:mt-5 mt-10 mb-5">
                Hasil Kalkulasi
              </h3>
              <div className="lg:grid grid-cols-2">
                <div className="calculate-result lg:col-span-1 lg:mr-2">
                  <p>Bunga (Sebelum Pajak)</p>
                  <p className="text-2lg font-bold">
                    <span>Rp</span>
                    {result.bungaSebelumPajak}
                  </p>
                </div>
                <div className="calculate-result lg:col-span-1 lg:ml-2 mt-4 lg:mt-0">
                  <p>Bunga (Setelah Pajak)</p>
                  <p className="text-2lg font-bold">
                    <span>Rp</span>
                    {result.bungaSetelahPajak}
                  </p>
                </div>
              </div>
              <div className="calculate-result mt-4">
                <p>Dana Setelah Tenor Berakhir</p>
                <p className="text-2lg font-bold">
                  <span>Rp</span>
                  {result.danaSetelahTenor}
                </p>
              </div>
              <div className="box-warning">
                <p>
                  *Simulasi ini hanya perkiraan saja. Silahkan hubungi&nbsp;
                  <a
                    href="https://api.whatsapp.com/send?phone=6285163106672"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="box-text-hyperlink"
                  >
                    Customer Happiness
                  </a>
                  &nbsp;DepositoBPR by Komunal
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BenefitDepositoBPR />
      <div className="mx-auto horizontal-green-border mt-16 lg:mt-0" />
      <ApaItuBPR />
      <CardDepositoBPR />
      <Footer />
    </Wrapper>
  );
};

export default Kalkulator;
